import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Head from './head'
import Header from './header'
import Footer from './footer';

import '../sass/main.scss'

const Layout = ({ children, title, description, cover, darkHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <Head title={title} cover={cover} description={description} />

    <div className="flex flex-col min-h-full">
      <Header siteTitle={data.site.siteMetadata.title} darkHeader={darkHeader} />

      {children}

      <Footer />
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  cover: PropTypes.any,
  darkHeader: PropTypes.bool,
}

Layout.defaultProps = {
  darkHeader: false,
  description: null,
  cover: null,
}

export default Layout

import React from 'react';

const Footer = () => (
    <>
      <footer className="flex-grow text-gray-500 text-sm py-8">
        <div className="container">
          <div className="flex justify-between items-start">

            {/* left */}
            <div className="flex">
              <p className="mb-1 text-sm">
                &copy; {new Date().getFullYear()} tail.dev
              </p>
            </div>

            {/* right */}
            <div>
                <a href="https://github.com/taildev" className="text-gray-500 mr-4" style={{fontSize: '18px'}}><i className="fab fa-github" /></a>
            </div>

          </div>{/* flex */}
        </div>{/* container */}
      </footer>
    </>
);

export default Footer;
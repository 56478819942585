import React from "react"
import { Link } from "gatsby"
import PropTypes from 'prop-types'

import lightLogo from '../images/logo/logo-light.svg'
import darkLogo from '../images/logo/logo-dark.svg'

const Header = ({ darkHeader }) => {

  return (
    <header className={`py-6 ${darkHeader ? 'bg-gray-900' : 'bg-white'}`}>
      <div className="container flex justify-between items-center">
        <Link to="/">
          <img src={darkHeader ? lightLogo : darkLogo} alt="tail.dev" style={{width: '100px'}} />
        </Link>

        <div className="text-sm">
          <span className="hidden sm:inline-block">
            <Link to="/documentation" className={`py-2 px-3 ${darkHeader ? 'text-gray-300 hover:text-gray-500' : 'text-gray-500 hover:text-gray-400'}`}>Documentation</Link>
            <Link to="/blog" className={`py-2 px-3 mr-8 ${darkHeader ? 'text-gray-300 hover:text-gray-500' : 'text-gray-500 hover:text-gray-400'}`}>Blog</Link>
          </span>

          <a href="https://console.tail.dev" className="border border-blue-500 text-blue-500 py-2 px-3 text-sm rounded hover:border-blue-400 hover:text-blue-400">
            <i className="fas fa-user fa-xs relative" style={{left: '-2px'}} /> Login
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  darkHeader: PropTypes.bool,
}

Header.defaultProps = {
  darkHeader: false,
}

export default Header

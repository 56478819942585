import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import defaultCover from '../images/covers/open-graph-default.png'

function Head({ description, lang, meta, title, cover }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  cover = cover || defaultCover

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? title + ' - tail.dev' : 'tail.dev'}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:image`,
          content: site.siteMetadata.siteUrl + cover,
        },
        {
          name: `og:title`,
          content: title || 'tail.dev',
        },
        {
          name: `twitter:card`,
          content: 'summary_large_image',
        },
        {
          name: `twitter:title`,
          content: title || 'tail.dev',
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: site.siteMetadata.siteUrl + cover + '?v=2',
        },
      ].concat(meta)}
    >
      <link href="https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css" integrity="sha512-1PKOgIY59xJ8Co8+NE6FZ+LOAZKjy+KY8iq0G4B3CyeY6wYHN3yt9PW0XpSriVlkMXe40PTKnXrLnZ9+fkDaog==" crossOrigin="anonymous" />
    </Helmet>
  )
}

Head.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: '',
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  cover: PropTypes.any,
}

Head.defaultProps = {
  cover: null,
  meta: [],
}

export default Head
